<template>
  <v-row>
    <v-col class="pa-3" cols="12">
      <h2>개인정보처리방침</h2>
      <p class="text-subtitle-1">
        주식회사 프렌들리(이하 &#39;회사&#39;)는 ｢개인정보 보호법｣ 제30조에 따라
        정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게
        처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
        수립·공개합니다.
      </p>
      <h3>제1조 (개인정보의 처리목적)</h3>
      <p class="text-subtitle-1">
        회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
        개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
        변경되는 경우에는 ｢개인정보 보호법｣ 제18조에 따라 별도의 동의를 받는 등
        필요한 조치를 이행할 예정입니다.
      </p>
      <ol>
        <li>
          <p class="text-subtitle-1">
            회원 관리 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인
            식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른
            본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시
            법정대리인 동의 여부 확인, 각종 고지·통지, 고충처리, 분쟁 조정을
            위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            민원사무 처리<br />민원인의 신원 확인, 민원사항 확인, 사실조사를
            위한 연락·통지, 처리결과 통보 등을 목적으로 개인정보를 처리합니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            재화 또는 서비스 제공<br />물품배송, 서비스 제공, 청구서 발송,
            콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산,
            채권추심 등을 목적으로 개인정보를 처리합니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            마케팅 및 광고에의 활용<br />신규 서비스(제품) 개발 및 맞춤 서비스
            제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적
            특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도
            파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를
            처리합니다.
          </p>
        </li>
      </ol>
      <p>&nbsp;<br />&nbsp;</p>
      <h3>제2조 (개인정보의 처리 및 보유기간)</h3>
      <ol>
        <li>
          <p class="text-subtitle-1">
            회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터
            개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서
            개인정보를 처리·보유합니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
          </p>
          <ol>
            <li>
              <p class="text-subtitle-1">
                서비스 회원 가입 및 관리 : 서비스 탈퇴 시까지 다만, 다음의
                사유에 해당하는 경우에는 해당 사유 종료 시까지 1. 관계 법령
                위반에 따른 수사․조사 등이 진행 중인 경우에는 해당 수사·조사
                종료 시까지 2. 서비스 이용에 따른 채권·채무관계 잔존 시에는 해당
                채권·채무관계 정산 시까지
              </p>
            </li>
            <li>
              <p class="text-subtitle-1">
                재화 또는 서비스 제공 : 재화·서비스 공급완료 및 요금결제·정산
                완료시까지 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료
                시까지 1. 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른
                표시·광고, 계약내용 및 이행 등 거래에 관한 기록 - 표시·광고에
                관한 기록 : 6개월 - 계약 또는 청약철회, 대금결제, 재화 등의
                공급기록 : 5년 - 소비자 불만 또는 분쟁처리에 관한 기록 : 3년 2.
                「통신비밀보호법」에 따른 통신사실확인자료 보관 - 가입자
                전기통신일시, 개시․종료시간, 상대방 가입자번호, 사용도수,
                발신기지국 위치추적자료 : 1년 - 컴퓨터통신, 인터넷 로그기록자료,
                접속지 추적자료 : 3개월
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p>&nbsp;<br />&nbsp;</p>
      <h3>제3조 (개인정보의 제3자 제공)</h3>
      <p class="text-subtitle-1">
        회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위
        내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 ｢개인정보
        보호법｣ 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게
        제공합니다.
      </p>
      <p>&nbsp;<br />&nbsp;</p>
      <h3>제4조 (개인정보처리의 위탁)</h3>
      <p class="text-subtitle-1">
        회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를
        위탁하고 있습니다.
      </p>
      <ul>
        <li>
          <p>(주)페이플<br />전자 결제 서비스</p>
        </li>
        <li>
          <p>(주)다날<br />전자 결제 서비스</p>
        </li>
        <li>
          <p>(주)아임포트<br />전자 결제 서비스</p>
        </li>
        <li>
          <p>(주)나이스페이먼츠<br />전자 결제 서비스</p>
        </li>
        <li>
          <p>(주)토스페이먼츠<br />전자 결제 서비스</p>
        </li>
        <li>
          <p>(주)나이스평가정보<br />본인 인증 서비스</p>
        </li>
        <li>
          <p>
            (주)코드에프<br />신용정보주체의 인증 정보를 이용하여
            개인(신용)정보를 중계, API 서비스 품질향상 및 업무 대응을 위해
            신용정보주체 인증 정보를 통한 요청/응답 데이터 저장(위탁 계약 종료
            시 일괄 삭제), 정보주체의 개인정보 열람, 정정.삭제, 처리 정지 요청
            등을 처리
          </p>
        </li>
        <li>
          <p>더치트<br />사기 피해 사례가 등록된 계좌 여부 확인</p>
        </li>
      </ul>
      <p class="text-subtitle-1">
        개인정보의 보유 및 이용기간 : 회원탈퇴시 혹은 위탁계약 종료시까지
      </p>
      <ol start="2">
        <li>
          <p class="text-subtitle-1">
            회사는 위탁계약 체결 시 ｢개인정보 보호법｣ 제26조에 따라 위탁업무
            수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한,
            수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등
            문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고
            있습니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보
            처리방침을 통하여 공개하도록 하겠습니다.<br />&nbsp;<br />&nbsp;
          </p>
        </li>
      </ol>
      <h3>제5조 (정보주체와 법정대리인의 권리․의무 및 행사방법)</h3>
      <ol>
        <li>
          <p class="text-subtitle-1">
            정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지
            요구 등의 권리를 행사할 수 있습니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            제1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행령
            제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수
            있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
            대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한
            고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야
            합니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조
            제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
            대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구,
            처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
            대리인인지를 확인합니다.
          </p>
        </li>
      </ol>
      <p>&nbsp;<br />&nbsp;</p>
      <h3>제6조 (처리하는 개인정보 항목)</h3>
      <p class="text-subtitle-1">
        회사는 다음의 개인정보 항목을 처리하고 있습니다.
      </p>
      <ul>
        <li>
          회원 가입
          <ul>
            <li>필수항목: 휴대폰번호</li>
            <li>선택항목: 아이디, 비밀번호, SNS 아이디</li>
          </ul>
        </li>
        <li>
          본인 인증
          <ul>
            <li>
              이름, 성별, 생년월일, 휴대폰번호, 통신사업자, 내/외국인 여부,
              암호화된 이용자 확인값(CI), 중복가입확인정보(DI)
            </li>
          </ul>
        </li>
        <li>
          결제 및 혜택 서비스
          <ul>
            <li>
              (회원이 등록한 카드사의) 보유 카드, 카드명, 카드번호(일부), 카드
              종류, 결제계좌, 예금주명, 결제 가맹점 정보, 결제 방법(간편결제
              등), 결제일, 이용 금액, 이용 일시, 승인번호, 승인내역, 청구내역,
              개인 (신용) 정보
            </li>
          </ul>
        </li>
        <li>
          환불 처리
          <ul>
            <li>계좌은행, 계좌번호, 예금주명, 이메일</li>
          </ul>
        </li>
        <li>
          서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수
          있습니다.
          <ul>
            <li>
              PC웹, 모바일 웹/앱 이용 과정에서 단말기정보(OS, 화면사이즈,
              디바이스 아이디, 폰기종, 단말기 모델명), IP주소, 쿠키, 방문일시,
              부정이용기록, 서비스 이용 기록 등
            </li>
          </ul>
        </li>
      </ul>
      <p>&nbsp;<br />&nbsp;</p>
      <h3>제7조 (개인정보의 파기)</h3>
      <ol>
        <li>
          <p class="text-subtitle-1">
            회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
            불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
            달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야
            하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
            보관장소를 달리하여 보존합니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            개인정보 파기의 절차 및 방법은 다음과 같습니다.
          </p>
          <ol>
            <li>
              <p class="text-subtitle-1">
                파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의
                개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
              </p>
            </li>
            <li>
              <p class="text-subtitle-1">
                파기방법 : 회사는 전자적 파일 형태로 기록·저장된 개인정보는
                기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된
                개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p>&nbsp;<br />&nbsp;</p>
      <h3>제8조 (개인정보의 안전성 확보조치)</h3>
      <p class="text-subtitle-1">
        회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
      </p>
      <ol>
        <li>
          <p class="text-subtitle-1">
            관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
            설치, 고유식별정보 등의 암호화, 보안프로그램 설치
          </p>
        </li>
      </ol>
      <p>&nbsp;<br />&nbsp;</p>
      <h3>
        제9조 (개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)
      </h3>
      <ol>
        <li>
          <p class="text-subtitle-1">
            회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를
            저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
          </p>
        </li>
        <li>
          <p class="text-subtitle-1">
            쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터
            브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
            하드디스크에 저장되기도 합니다.
          </p>
          <ol>
            <li>
              <p class="text-subtitle-1">
                쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한
                방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여
                이용자에게 최적화된 정보 제공을 위해 사용됩니다.
              </p>
            </li>
            <li>
              <p class="text-subtitle-1">
                쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷
                옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
                있습니다.
              </p>
            </li>
            <li>
              <p class="text-subtitle-1">
                쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
                있습니다.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p>&nbsp;<br />&nbsp;</p>
      <h3>제10조 (개인정보 보호책임자)</h3>
      <ol>
        <li>
          <p class="text-subtitle-1">
            회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
            처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
            같이 개인정보 보호책임자를 지정하고 있습니다.
          </p>
          <ul>
            <li>개인정보 보호책임자 및 담당부서</li>
            <li>
              <ul>
                <li>
                  책임자: 황세안 (개인정보 보호책임자/CTO)
                </li>
              </ul>
            </li>
            <li>
              <ul>
                <li>담당부서: 개인정보보호파트</li>
              </ul>
            </li>
            <li>
              <ul>
                <li>이메일문의: babby108@naver.com</li>
                <li>전화문의: 010-3100-1777</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p class="text-subtitle-1">
            정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든
            개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
            개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는
            정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
          </p>
        </li>
      </ol>
      <p>&nbsp;<br />&nbsp;</p>
      <h3>제11조 (추가적인 이용·제공 판단기준)</h3>
      <p class="text-subtitle-1">
        회사는 ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보
        보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이
        개인정보를 추가적으로 이용·제공할 수 있습니다.
      </p>
      <p class="text-subtitle-1">
        이에 따라 회사가(이) 정보주체의 동의 없이 추가적인 이용·제공을 하기
        위해서 다음과 같은 사항을 고려하였습니다.
      </p>
      <ul>
        <li>
          개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이
          있는지 여부
        </li>
        <li>
          개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인
          이용·제공에 대한 예측 가능성이 있는지 여부
        </li>
        <li>
          개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지
          여부
        </li>
        <li>
          가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부
        </li>
      </ul>
      <p>
        ※ 추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체 스스로
        자율적으로 판단하여 작성·공개함
      </p>
      <p>&nbsp;<br />&nbsp;</p>
      <h3>제12조 (개인정보 열람청구)</h3>
      <p class="text-subtitle-1">
        정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의
        부서에 할 수 있습니다.
      </p>
      <ul>
        <li>
          개인정보 열람청구 접수․처리 부서
          <ul>
            <li>담당부서: 개인정보보호파트</li>
            <li>담당자: 황세안</li>
            <li>전화: 010-3100-1777</li>
            <li>이메일: babby108@naver.com</li>
          </ul>
        </li>
      </ul>
      <p class="text-subtitle-1">
        회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록
        노력하겠습니다.
      </p>
      <p>&nbsp;<br />&nbsp;</p>
      <h3>제13조 (권익침해 구제방법)</h3>
      <p class="text-subtitle-1">
        정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을
        문의하실 수 있습니다.
      </p>
      <p class="text-subtitle-1">
        &lt;아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보
        불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이
        필요하시면 문의하여 주시기 바랍니다&gt;
      </p>
      <ul>
        <li>
          <p class="text-subtitle-1">개인정보 침해신고센터 (한국인터넷진흥원 운영)</p>
          <ul>
            <li>소관업무 : 개인정보 침해사실 신고, 상담 신청</li>
            <li>홈페이지 : privacy.kisa.or.kr</li>
            <li>전화 : (국번없이) 118</li>
            <li>
              주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
            </li>
          </ul>
        </li>
        <li>
          <p>개인정보 분쟁조정위원회</p>
          <ul>
            <li>
              소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
            </li>
            <li>
              홈페이지 :
              <a href="http://www.kopico.go.kr">www.kopico.go.kr</a>
            </li>
            <li>전화 : (국번없이) 1833-6972</li>
            <li>
              주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 12층
            </li>
          </ul>
        </li>
        <li>
          <p>
            대검찰청 사이버범죄수사단 : 02-3480-3573 (<a
              href="http://www.spo.go.kr"
              >www.spo.go.kr</a
            >)
          </p>
        </li>
        <li>
          <p>
            경찰청 사이버안전국 : 182 (<a
              href="https://cyberbureau.police.go.kr"
              >https://cyberbureau.police.go.kr</a
            >)
          </p>
        </li>
      </ul>
      <p>&nbsp;<br />&nbsp;</p>
      <h3>제14조 (개인정보 처리방침 변경)</h3>
      <p class="text-subtitle-1">
        회사는 법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로
        개인정보처리방침을 수정할 수 있습니다. 이 개인정보 처리방침은 아래
        날짜부터 적용됩니다.
      </p>
      <ul>
        <li>공고일자: 2022년 1월 15일</li>
        <li>시행일자: 2021년 1월 15일</li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
// import axios from '../../service/axios'

export default {
  name: "TermService",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss">
.service_title {
  padding: 16px 0px 0px 0px;
  font-weight: 600;
}
.service_subtitle {
  padding: 24px 0px 12px 0;
  font-weight: 500;
}
.service {
  p {
    margin: 0px;
  }
  .service_main {
    margin: 0px 0px 4px 0px;
    font-size: 0.825rem;
  }
  .service_sub {
    margin: 0px 0px 2px 16px;
  }
}
</style>
